import React, { useState } from 'react';
import { HiPhoneArrowUpRight } from "react-icons/hi2";
import { MdPlayLesson, MdStickyNote2  } from "react-icons/md";
import Loading from '../../../components/Loading';
import {Link} from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
  ModalHeader,
  useToast,
} from '@chakra-ui/react'
import { GET } from '../../../utils/requests';
import { getuseruri } from '../../../uri';
import ProductSeeCard from './prodSeeCard';


const HomeIndex = () => {
  const [load, setLoad] = useState(false);
  const [phone_or_trackcode, setPhoneOrTrackcode] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const toast = useToast();

  function haha(){
    // Load({load: load})
  }

  const SubCheck = async () => {
    if(phone_or_trackcode === ""){
      return 
    }
    setLoad(true);
    const res = await GET(getuseruri+'/check-track'+`?data=${phone_or_trackcode}`);
    if(res.data.length !== 0){
      onOpen();
    }else{
      toast({title: 'Хайлтанд тохирох бараа байхгүй байна',status: 'warning',duration: 3000,isClosable: true, position: "top"})
    }
    setData(res.data);
    setLoad(false);
  }

  return (
    <div className='font-Roboto'>
      {
        load &&
        <Loading/>
      }
      <div className='mt-6'>
          <img src='../../home/hom22.png' className=' w-full xs:h-40 md:h-60 rounded-2xl ring-1 ring-white'/>
      </div>
      <h1 className='text-white uppercase font-bold mt-6 text-2xl'>Сайн каргонд тавтай морилно уу</h1>
      <div>
          <h1 className='text-white mt-8'>Бараа шалгах</h1>
          <input  onChange={(e) => setPhoneOrTrackcode(e.target.value)} className='w-full xs:p-2 md:p-4 rounded-md mt-2 bg-gray-200 outline-none focus:ring-3 ring-1 placeholder:text-sm ring-[#b39333]' placeholder='Утасны дугаар эсвэл бар кодоор хайх'/>
          <button onClick={SubCheck} className='bg-[#3A3AC5] text-white w-full p-2 rounded-md mt-2 font-bold'>Шалгах</button>
      </div>

      <div className='grid grid-cols-3 mt-4 gap-2 w-full xs:text-[9px] md:text-sm'>
        <Link to="/contact" className='bg-[#b39333] p-4 rounded-md w-full shadow-sm shadow-white cursor-pointer hover:bg-[#96803f] flex justify-between flex-col'>
          <div className='flex justify-center'>
            <HiPhoneArrowUpRight size={44} color='white'/>
          </div>
          <h1 className='text-white font-bold text-center mt-2'>Холбоо барих</h1>
        </Link>
        <Link to="/lesson" className='bg-[#b39333] p-4 rounded-md w-full shadow-sm shadow-white cursor-pointer hover:bg-[#96803f] flex justify-between flex-col'>
          <div className='flex justify-center'>
            <MdPlayLesson size={44} color='white'/>
          </div>
          <h1 className='text-white font-bold text-center'>Заавар сургалт</h1>
        </Link>
        <Link to="/service" className='bg-[#b39333] p-4 rounded-md w-full shadow-sm shadow-white cursor-pointer hover:bg-[#96803f] flex justify-between flex-col'>
          <div className='flex justify-center'>
            <MdStickyNote2 size={44} color='white'/>
          </div>
          <h1 className='text-white font-bold text-center'>Үйлчилгээний нөхцөл</h1>
        </Link>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} placement='bottom' scrollBehavior="inside" size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
              <div className='mt-8 mb-10'>
                <h1 className='mb-2'>Хайлтанд илэрсэн бараа</h1>
                {
                  data.map((it, index) => {
                    return(
                      <ProductSeeCard data={it} key={index}/>
                    )
                  })
                }
              </div>
          </ModalBody>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default HomeIndex